import { ProgressCircle, Flex, View } from '@adobe/react-spectrum';
import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { Spinner } from '@nike/eds';

import { RequiresAuth } from '@nike.innovation/auth-guard';
import { ThreeDModelPage } from './threed-model-page/threed-model-page';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="" element={<RequiresAuth loadingElement={<Spinner />} />}>
        <Route path="" element={<ThreeDModelPage />} />
      </Route>

      <Route
        path="/implicit/callback"
        element={
          <LoginCallback
            loadingElement={
              <View minHeight="100vh">
                <Flex direction="column" gap="size-100">
                  <Flex marginTop="size-25" direction="column" gap="size-500" alignItems="center">
                    <ProgressCircle aria-label="Loading…" isIndeterminate />
                  </Flex>
                </Flex>
              </View>
            }
          />
        }
      />
    </Routes>
  );
}

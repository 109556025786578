import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { match } from 'ts-pattern';
import { oktaAuth } from './utils/okta-auth';
import { AppRoutes } from './routes';

import '@nike/eds/dist/index.css';

const trackingId = match(window.location.host)
  .with('composure-test.nike.io', () => 'G-Z7HH3FKS93')
  .with('composure.nike.io', () => 'G-G27EFHG47M')
  .otherwise(() => 'G-F03JSEKVJZ');
ReactGA.initialize(trackingId);

export function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true });
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <main>
        <AppRoutes />
      </main>
    </Security>
  );
}

export default App;

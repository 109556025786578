// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { Environment } from '@nike.innovation/composure-sdk';

export interface ComposureEnvironment {
  production: boolean;
  adGroup: string;
  issuer: string;
  clientId: string;
  apiBaseUrl: string;
  aegisBaseUrl: string;
  composureApiEnvironment: Environment;
}

export const environment: ComposureEnvironment = {
  production: false,
  adGroup: 'App.Composure.Test.Users',
  issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  clientId: 'nike.picc.composure',
  apiBaseUrl: 'http://localhost:4080',
  aegisBaseUrl: 'https://api.aegis.nikecloud.com/v1/qa',
  composureApiEnvironment: 'local',
};

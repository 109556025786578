/* eslint-disable */
// These are scripts that will be run in AI (it's JavaScript but it's not run by a web browser)
// For details see: https://ai-scripting.docsforadobe.dev/
export const importCurves = {
  addCircle(targetLayer, x, y, radius) {
    var doc = app.activeDocument;
    // Find the desired layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      // Maker sure we are unlocked
      var lockedState = layer.locked;
      layer.locked = false;

      // Add circle
      var circle = layer.pathItems.ellipse(y, x, radius, radius);
      circle.fillColor = new NoColor();

      // Restore locked state
      layer.locked = lockedState;
    }
  },
  addPolyline(targetLayer, data, closed, strokeColorString, strokeWidth, fillColorString) {
    var tokens = data.split(',');
    var doc = app.activeDocument;

    // Process inputs
    var filled = fillColorString !== '';
    var stroked = strokeColorString !== '' && strokeWidth > 0;

    var fillColor = new NoColor();
    if (filled) {
      var fillStr = fillColorString.split(',');
      fillColor = new RGBColor();
      fillColor.red = parseFloat(fillStr[0]);
      fillColor.green = parseFloat(fillStr[1]);
      fillColor.blue = parseFloat(fillStr[2]);
    }

    var strokeColor = new NoColor();
    if (stroked) {
      var strokeStr = strokeColorString.split(',');
      strokeColor = new RGBColor();
      strokeColor.red = parseFloat(strokeStr[0]);
      strokeColor.green = parseFloat(strokeStr[1]);
      strokeColor.blue = parseFloat(strokeStr[2]);
    }

    // Find the desired layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      // Maker sure we are unlocked
      layer.locked = false;

      var polyline = layer.pathItems.add();
      polyline.stroked = stroked;
      polyline.strokeColor = strokeColor;
      polyline.strokeWidth = strokeWidth;
      polyline.filled = filled;
      polyline.fillColor = fillColor;
      polyline.closed = closed;
      polyline.pixelAligned = false;

      for (var index = 0; index < tokens.length; index += 2) {
        var x = parseFloat(tokens[index]);
        var y = parseFloat(tokens[index + 1]);

        var point = polyline.pathPoints.add();
        point.anchor = [x, y];
        point.leftDirection = point.anchor;
        point.rightDirection = point.anchor;
        point.pointType = PointType.CORNER;
      }
    }

    // Redraw to cover a bug where Illstrator doesn't refresh
    redraw();
  },

  addClosedPolyline(targetLayer, data, closed, strokeColorString, strokeWidth, fillColorString) {
    var tokens = data.split(',');
    var doc = app.activeDocument;

    // Process inputs
    var filled = fillColorString !== '';
    var stroked = strokeColorString !== '' && strokeWidth > 0;

    var fillColor = new NoColor();

    var strokeColor = new NoColor();
    if (stroked) {
      var strokeStr = strokeColorString.split(',');
      strokeColor = new RGBColor();
      strokeColor.red = parseFloat(strokeStr[0]);
      strokeColor.green = parseFloat(strokeStr[1]);
      strokeColor.blue = parseFloat(strokeStr[2]);
    }

    // Find the desired layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      // Maker sure we are unlocked
      layer.locked = false;

      var polyline = layer.pathItems.add();
      polyline.stroked = stroked;
      polyline.strokeColor = strokeColor;
      polyline.strokeWidth = strokeWidth;
      polyline.filled = filled;
      polyline.fillColor = fillColor;
      polyline.closed = closed;
      polyline.pixelAligned = false;

      for (var index = 0; index < tokens.length; index += 2) {
        var x = parseFloat(tokens[index]);
        var y = parseFloat(tokens[index + 1]);

        var point = polyline.pathPoints.add();
        point.anchor = [x, y];
        point.leftDirection = point.anchor;
        point.rightDirection = point.anchor;
        point.pointType = PointType.CORNER;
      }
    }

    // Redraw to cover a bug where Illstrator doesn't refresh
    redraw();
  },

  addEntirePolyline(targetLayer, data, closed, strokeColorString, strokeWidth, fillColorString) {
    var tokens = data.split(',');
    var doc = app.activeDocument;

    // Process inputs
    var filled = fillColorString !== '';
    var stroked = strokeColorString !== '' && strokeWidth > 0;

    var fillColor = new NoColor();
    if (filled) {
      var fillStr = fillColorString.split(',');
      fillColor = new RGBColor();
      fillColor.red = parseFloat(fillStr[0]);
      fillColor.green = parseFloat(fillStr[1]);
      fillColor.blue = parseFloat(fillStr[2]);
    }

    var strokeColor = new NoColor();
    if (stroked) {
      var strokeStr = strokeColorString.split(',');
      strokeColor = new RGBColor();
      strokeColor.red = parseFloat(strokeStr[0]);
      strokeColor.green = parseFloat(strokeStr[1]);
      strokeColor.blue = parseFloat(strokeStr[2]);
    }

    // Find the desired layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      // Maker sure we are unlocked
      var lockedState = layer.locked;
      layer.locked = false;

      // Break into groups of 1000 points
      // setEntirePath has a limit to the number of points
      var maxX = 0;
      var maxY = 0;
      var polylines = [];

      var points = [];
      for (var index = 0; index < tokens.length; index += 2) {
        var x = parseFloat(tokens[index]);
        var y = parseFloat(tokens[index + 1]);
        points.push([x, y]);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);

        if (points.length >= 1000) {
          polylines.push(points);
          points = [];
        }
      }

      polylines.push(points);

      for (var index = 0; index < polylines.length; index++) {
        var polyline = layer.pathItems.add();
        polyline.stroked = stroked;
        polyline.strokeColor = strokeColor;
        polyline.strokeWidth = strokeWidth;
        polyline.filled = filled;
        polyline.fillColor = fillColor;
        polyline.closed = closed;
        polyline.pixelAligned = false;
        polyline.setEntirePath(polylines[index]);
      }

      // Restore locked state
      layer.locked = lockedState;
    }

    // Redraw to cover a bug where Illstrator doesn't refresh
    redraw();
  },

  // Adds points as a bezier curve (haven't been tested a lot)
  //      1  ---------  2
  //        /         \
  //       /           \
  //      /             \
  //   0 -               - 3
  // Format of points should be anchor 0 and 3 with control (direction) points 1 and 2.
  // First and last points don't has one control point.
  addCurve(targetLayer, points, closed, strokeColorString, strokeWidth, fillColorString) {
    var tokens = points.split(',');
    var doc = app.activeDocument;

    // Process inputs
    var filled = fillColorString !== '';
    var stroked = strokeColorString !== '' && strokeWidth > 0;

    var fillColor = new NoColor();
    if (filled) {
      var fillStr = fillColorString.split(',');
      fillColor = new RGBColor();
      fillColor.red = parseFloat(fillStr[0]);
      fillColor.green = parseFloat(fillStr[1]);
      fillColor.blue = parseFloat(fillStr[2]);
    }

    // Find the desired layer
    var strokeColor = new NoColor();
    if (stroked) {
      var strokeStr = strokeColorString.split(',');
      strokeColor = new RGBColor();
      strokeColor.red = parseFloat(strokeStr[0]);
      strokeColor.green = parseFloat(strokeStr[1]);
      strokeColor.blue = parseFloat(strokeStr[2]);
    }

    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      // Maker sure we are unlocked
      var lockedState = layer.locked;
      layer.locked = false;

      var polyline = layer.pathItems.add();
      curve.stroked = stroked;
      curve.strokeColor = strokeColor;
      curve.strokeWidth = strokeWidth;
      curve.filled = filled;
      curve.fillColor = fillColor;
      curve.closed = closed;

      // First points
      var x = parseFloat(tokens[0]);
      var y = parseFloat(tokens[1]);
      var lx = x;
      var ly = y;
      var rx = parseFloat(tokens[2]);
      var ry = parseFloat(tokens[3]);

      var point = polyline.pathPoints.add();
      point.anchor = [x, y];
      point.leftDirection = [lx, ly];
      point.rightDirection = [rx, ry];
      point.pointType = PointType.SMOOTH;

      // Add all middle points
      for (var index = 4; index < tokens.length - 4; index += 6) {
        lx = parseFloat(tokens[index]);
        ly = parseFloat(tokens[index + 1]);
        x = parseFloat(tokens[index + 2]);
        y = parseFloat(tokens[index + 3]);
        rx = parseFloat(tokens[index + 4]);
        ry = parseFloat(tokens[index + 5]);

        point = polyline.pathPoints.add();
        point.anchor = [x, y];
        point.leftDirection = [lx, ly];
        point.rightDirection = [rx, ry];
        point.pointType = PointType.SMOOTH;
      }

      // Add the last point
      lx = parseFloat(tokens[tokens.length - 4]);
      ly = parseFloat(tokens[tokens.length - 3]);
      x = parseFloat(tokens[tokens.length - 2]);
      y = parseFloat(tokens[tokens.length - 1]);
      rx = x;
      ry = y;

      point = polyline.pathPoints.add();
      point.anchor = [x, y];
      point.leftDirection = [lx, ly];
      point.rightDirection = [rx, ry];
      point.pointType = PointType.SMOOTH;
      layer.locked = lockedState;
    }
  },

  addCircle(targetLayer, x, y, radius) {
    var doc = app.activeDocument;
    // Find the desired layer
    var layer = null;
    for (var index = 0; index < doc.layers.length; index++) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
        break;
      }
    }

    if (layer) {
      // Maker sure we are unlocked
      var lockedState = layer.locked;
      layer.locked = false;

      // Add circle
      var circle = layer.pathItems.ellipse(y, x, radius, radius);
      circle.fillColor = new NoColor();

      // Restore locked state
      layer.locked = lockedState;
    }
  },

  importSVG(targetLayer, path, x, y, width, height) {
    var doc = app.activeDocument;

    // Find the desired layer
    // Note: Oddly, we also need to unlock all layers for createFromFile to work
    var locked = [];
    var layer = null;
    for (var index = doc.layers.length - 1; index >= 0; index--) {
      if (doc.layers[index].name === targetLayer) {
        layer = doc.layers[index];
      }

      locked.unshift(doc.layers[index].locked);
      doc.layers[index].locked = false;
    }

    if (layer) {
      var svgFile = new File(path); // !! Make sure you pass in a path with /'s (e.g. C:/Users/Luna/Desktop/eg.svg)
      var groupItem = layer.groupItems.createFromFile(svgFile);
      groupItem.hidden = true;

      // Position and scale groupItem
      groupItem.left = x - 0.5;
      groupItem.top = y + 0.5;
      groupItem.width = width;
      groupItem.height = height;

      // Flatten imported page items into a single layer
      var processPageItems = function (pageItems, moveToLayer) {
        for (var index = pageItems.length - 1; index >= 0; index--) {
          if (pageItems[index].typename === 'GroupItem') {
            processPageItems(pageItems[index].pageItems);
            pageItems[index].remove();
          } else {
            pageItems[index].move(moveToLayer, ElementPlacement.PLACEATEND);
          }
        }
      };
    }

    processPageItems(groupItem.pageItems, layer);
    groupItem.remove();

    // Find the boundary shape
    var maxArea = 0;
    var sceneBounds = undefined;

    for (var index = 0; index < layer.pathItems.length; index++) {
      var pathItem = layer.pathItems[index];
      var area = Math.abs(pathItem.area);
      if (area > maxArea && pathItem.pathPoints.length === 4) {
        maxArea = area;
        sceneBounds = pathItem;
      }
    }

    if (sceneBounds) {
      sceneBounds.remove();
    }

    // Restore locked state
    for (var index = doc.layers.length - 1; index >= 0; index--) {
      doc.layers[index].locked = locked[index];
    }
  },

  importFile(path) {
    var doc = app.activeDocument;

    var file = new File(path);
    doc.groupItems.createFromFile(file);
  },
};

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { defaultTheme, Provider } from '@adobe/react-spectrum';

import App from './app/app';

// This is to make click events work in an adobe extension on Mac for Spectrum
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
delete window.PointerEvent;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const script = document.createElement('script');
script.src = 'https://cdn.jsdelivr.net/npm/rhino3dm@8.0.0-beta/rhino3dm.min.js';

script.addEventListener('load', () => {
  root.render(
    <StrictMode>
      <Provider theme={defaultTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </StrictMode>
  );
});
document.body.appendChild(script);

import { Environment } from '@nike.innovation/composure-sdk';

export interface ApolloEnvironment {
  production: boolean;
  adGroup: string;
  issuer: string;
  clientId: string;
  composureEnvironment: Environment;
  scriptId: string;
  midsoleId: string;
}

export const environment: ApolloEnvironment = {
  production: false,
  adGroup: 'App.Composure.Test.Users',
  issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  clientId: 'nike.picc.composure',
  composureEnvironment: 'test',
  scriptId: '8e870532-29e2-47b0-b86b-905c2e593904',
  midsoleId: 'f22fb102-1027-45bb-9d2b-9929ac556fc5',
};
